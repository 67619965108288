import Vue from "vue";
import VueI18n from "vue-i18n";
import defaultLocaleMessages from "./locales/de";

Vue.use(VueI18n);

export const availableLocales = ["de", "fr", "en"]; // First locale is the default
export const defaultLocale = availableLocales[0];
const loadedLocales = [defaultLocale]; // our default language that is preloaded
const defaultMessagesConfig = {};
defaultMessagesConfig[defaultLocale] = {
  ...defaultLocaleMessages,
};

export const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: defaultMessagesConfig,
  silentTranslationWarn: true, // Removes [vue-i18n] Value of key '<<value>>' is not a string!
});

function setI18nLocale(locale) {
  i18n.locale = locale;
  document.querySelector("html").setAttribute("lang", locale);

  return locale;
}

export async function loadLocaleAsync(language) {
  if (i18n.locale !== language) {
    if (!loadedLocales.includes(language)) {
      return import(`@/i18n/locales/${language}`).then((localeModule) => {
        i18n.setLocaleMessage(language, localeModule.default);
        loadedLocales.push(language);

        return setI18nLocale(language);
      });
    }

    return Promise.resolve(setI18nLocale(language));
  }

  return Promise.resolve(language);
}
