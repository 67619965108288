export default {
  firebase_service: {
    error_codes: {
      // Covers fetchSignInMethodsForEmail, signInWithEmailAndPassword, sendPasswordResetEmail, verifyPasswordResetCode,
      // confirmPasswordReset
      'auth/invalid-email': '@:(errors.emailInvalidFormat)',
      'auth/user-disabled': 'Dieses Benutzerkonto wurde von einem Administrator gesperrt.',
      'auth/user-not-found': '@:(errors.accountNotFound)',
      'auth/wrong-password': '@:(errors.passwordIsWrong)',
      'auth/weak-password': 'Das Passwort ist nicht stark genug.',
      'auth/too-many-requests':
        'Wegen ungewöhnlich hoher Aktivität haben wir alle Anforderungen von diesem Gerät blockiert. Versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
      'auth/expired-action-code': 'Der Kennwortrücksetzungscode ist abgelaufen.',
      'auth/invalid-action-code':
        'Der Kennwortrücksetzcode ist ungültig. Der Code ist fehlerhaft oder wurde bereits verwendet.',
      'auth/user-token-expired':
        'Der Token dieses Benutzers ist nicht mehr gültig. Der Benutzer muss sich wieder einloggen.',
      'auth/invalid-user-token': 'Der Refresh-Token ist ungültig.',
      'auth/invalid-credential': 'Der angegebene Grant-Type ist ungültig.',
    },
  },
  errors: {
    emailRequired: 'Die E-Mail-Adresse ist erforderlich.',
    emailInvalidFormat: 'Die E-Mail-Adresse ist nicht richtig formatiert.',
    passwordRequired: 'Das Passwort ist erforderlich.',
    passwordTooShort: 'Das eingegebene Passwort ist zu kurz.',
    passwordIsWrong: 'Dieses Passwort ist ungültig.',
    accountNotFound: 'Dieser Benutzer ist uns nicht bekannt.',
    passwordpasswordMismatch: 'Passwörter stimmen nicht überein',
    passwordFormatIsInvalid:
      'Das Passwort muss mind. 1 Grossbuchstaben, 1 Kleinbuchstaben und 1 Ziffer enthalten',
  },
  language_display_names: {
    de: 'Deutsch',
    fr: 'Französisch',
    en: 'Englisch',
  },

  generic_crud_labels: {
    save: 'Speichern',
    cancel: 'Abbrechen',
    back: 'Zurück',
    next: 'Weiter',
    edit: 'Bearbeiten',
    print: 'Drucken',
    preview: 'Vorschau',
    delete: 'Löschen',
    duplicate: 'Duplizieren',
  },
  order_footer: {
    internal_processing_mark: 'Bearbeitungsvermerk GALVASWISS Werk {locationName} Intern',
    in: 'Eingang',
    out: 'Ausgang',
    door_nr: 'Touren Nr.',
    delivery_date: 'Lieferdatum',
    delivery_date_nocommitment: 'unverbindlicher Liefertermin',
    area_door: 'Gebiet/Tour',
    lot: 'Los Nr.',
    car: 'Motorwagen',
    trailer: 'Anhänger',
    lorry: 'Auflieger',
    loading_position: 'Ladeposition',
  },
  order_body: {
    no_treatment: 'Keine Behandlung',
    print_instructions:
      'Bitte drucken Sie den E-Lieferschein aus und geben Sie diesen dem {driver} mit.',
    print_warning:
      'Achtung: Der ausgedruckte und übermittelte Lieferschein kann nicht mehr geändert werden.',
    print_and_send: 'Drucken und Übermitteln',
    cancel: 'Abbrechen',
    no_treatment_warning:
      'Sie haben keine Behandlung ausgewählt, wollen Sie trotzdem ohne Behandlungsart speichern?',
    no_treatment_warning_continue:
      'Sie haben keine Behandlung ausgewählt, wollen Sie trotzdem ohne Behandlungsart fortfahren?',
    shipping_address_warning: 'Please fill in your shipping address',
    no_transport_option_selected:
      'Es wurde kein Transportoption für die An- und Rücklieferung gewählt!',
    no_transport_option_hint:
      'Bitte wählen Sie für die An- und Rücklieferung den gewünschten Transportoption.',
    empty_shipping_address: 'Transportoption kontrollieren!',
    empty_threads_warning:
      'Bitte Anzahl Gewinde ausfüllen, Innengewinde oder Aussgewindereinigen -reinigen Optionen wählen.',
    print_order: 'E-Lieferschein ausdrucken',
    no_treatment_available: 'Keine Behandlung vorhanden',
    print_and_send_confirm: 'E-Lieferschein ausdrucken und übermitteln?',
    transport_option: 'Transportoption',
    title: 'Title',
    no_threads_title: 'Gewinde reinigen',
  },
  order_header: {
    order_human_readable_id: 'E-Lieferschein:',
    order_label: 'Bestell- / Lieferschein',
    important: 'Wichtig:',
    important_second: 'Dem {name} eine Kopie abgeben',
    phone: 'Telefon',
    fax: 'Telefax',
    email: 'Email',
    web: 'Web',
    billing_address_label: 'Rechnungsadresse',
    site_address: 'Versand- / Baustellenadresse',
    offer_nr: 'Offerten-Nr.:',
    delivery_date_main: 'Liefertermin:',
    pickup_date: 'Abholdatum:',
    delivery_date_nocommitment: 'unverbindlicher Liefertermin:',
    date: 'Erstelldatum',
    order_nr: 'Bestell-Nr.',
    commision: 'Kommission',
    order_number: 'Lieferschein-Nr.',
    firstname: 'Vorname',
    lastname: 'Name',
    enter_address: 'Adresse eingeben',
    zip: 'PLZ',
    company: 'Firmenname eingeben',
    city: 'Ort',
  },
  order_list: {},
  order_item: {
    preview: 'Vorschau',
    print: 'Drucken',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    duplicate: 'Duplizieren',
  },
  order_metal_components: {
    internal: 'Intern',
    per_order: 'Pro Bestellung bitte nur',
    per_order_note_stress: 'eine',
    per_order_note_end: 'Behandlungsart',
    tin_bath: 'Zinkbad: 16.0m Länge, 2.0m Breite, 3.0m Höhe',
    position: 'Pos',
    quantaty: 'Anzahl',
    description: 'Materialbezeichnung',
    image: 'Bild',
    dimentions: 'Profile & Dimensionen',
    length: 'Länge mm',
    width: 'Breite mm',
    depth: 'Tiefe mm',
    position_long: 'Position',
    add_order_line: 'Bestellposition Hinzufügen',
  },
  order_more_options: {
    treating: 'Behandlung',
    iso: 'Feuerverzinken ISO 1461',
    tolerance_zone: 'DASt022 Vertrauenszone:',
    only_zync: 'Nur Zink abbeizen und schwarz zurück',
    duroplex: 'Duroplex',
    fvz: '(FVZ + Nasslack)',
    thermoplex: 'Thermoplex',
    fvz_powder: '(FVZ + Pulver)',
    select_color_tone: 'Farbton auswählen',
    provide_color_tone: 'Geben Sie einen Farbton ein.',
    ral: 'Farbton',
    sz: 'S + Z',
    ferro: 'Ferrostyle-N (Nur Nasslack)',
    ferrow_only_powder: 'Ferrostyle-P (Nur Pulver)',
    select_color_tone_and_application: 'Farbton & Anwendung auswählen',
    please_provide_color_tone_and_application:
      'Geben Sie einen Farbton ein und wählen Sie eine Anwendung an.',
    inner_use: 'Innenanwendung',
    you_must_select: '(zwingend vermerken!)',
    outside_use: 'Aussenanwendung',
    galva: 'Galvanisch verzinken',
    galva_duration: '(Ca. 5 – 10 Arbeitstage)',
    glanz: 'Glanzverchromen',
    blue: 'Blauchromatieren',
    en1090: 'EN 1090',
    fill_en1090: 'Bitte füllen Sie, falls gewünscht die Kategorie EN 1090 aus.',
    corrosion: 'Korrosivitätskategorie:',
    preparation_level: 'Vorbereitungsgrad:',
    specials: 'Besonderes',
    clean: 'Gewinde reinigen',
    clean_thread_in: 'Innengewinde reinigen',
    clean_thread_out: 'Aussgewinde reinigen',
    pcs: 'Stk.',
    qs: 'QS',
    measurment_log: 'Messprotokoll Standard Fr. 50',
    measurment_log_de: 'Messprotokoll für Feuerverzinken',
    measurment_log_duro: 'Messprotokoll nach Kundenvorgabe, auf Anfrage',
    measurment_log_duro_de: 'Messprotokoll für Duroplex',
    holes: 'Löcher inwendig gebohrt',
    holes_closed: 'Löcher schliessen',
    no_holes: 'Keine zusätzlichen Löcher bohren',
    remove_zync: '* Zink entfernen/abbeizen',
    more_cost: '(Mehrkosten)',
    old_color: '* Altfarbe Ablaugen bei Unterlieferant',
    old_color_de: '* Altfarbe entfernen bei Unterlieferant',
    old_color_aarberg: '* Altfarbe Abbrennen bei Unterlieferant',
    price_on_request: '(Preis auf Anfrage)',
    only_with_meeting: '* Bedingt eine Terminverlängerung!',
    pack: 'Verpackung',
    eu_pallets: 'Euro-Paletten',
    quantaty_pallets: 'Anzahl:',
    pallet_frames: 'Palettenrahmen',
    half_pallete: '½ Palettenrahme',
    one_way_pallete: 'Einwegpaletten',
    other: 'Sonstiges',
    transport: 'Transport',
    deliver_to: 'Anlieferung ins Werk {locationName}',
    lorry_galva: 'LKW Galvaswiss',
    self: 'Selbst',
    returns: 'Rücklieferung ab Werk {locationName}',
    terms_notes: "Hinweis zu AGB's",
    terms: 'Es gelten die allgemeinen Geschäftsbedingungen der',
    comments: 'Bemerkungen / Kontaktperson für Rückfragen',
  },
  auth_form: {},
  navi: {
    search_orders: 'Lieferschein Suche',
    drafts: 'Entwürfe',
    sent: 'Übermittelt',
    archived: 'Archiviert',
    create: 'Erstellen',
    create_order: 'Lieferschein erstellung',
    create_note: 'Erstellen Sie ganz einfach ihren Digitalen Lieferschein für GalvaSwiss.',
    support: 'Support',
    account_management: 'Kontoverwalten',
    logoff: 'Ausloggen',
    welcome: 'Willkommen auf der Galvaswiss AG E-Lieferschein Plattform',
    change_password: 'Passwort ändern',
    new_password: 'Neues Passwort',
    confirm_new_password: 'Neues Passwort bestätigen',
    password_changed: 'Passwort wurde geändert',
    i_accept: 'Ich akzeptiere die',
    terms_and_conditions: 'AGBs',
    from: 'der Galvaswiss AG.',
    data_security: 'Datenschutzerklärung',
    register: 'Registrieren',
  },
  auth_new_password: {
    email_sent_confirmation: 'Wir haben ihnen einen Bestätigungslink an',
    email_sent_confirmation_end: 'gesendet.',
    enter_email: 'E-Mail-Adresse zur Kontowiederherstellung eingeben.',
    send: 'Senden',
    to_login: 'Zur Anmeldung',
  },
  auth_set_new_password: {
    enter_new_password: 'Geben Sie ein neues Passwort für',
    enter_new_password_end: 'ein.',
    enter_new_password_again: 'Geben Sie ein neues Passwort ein.',
  },
  signin: {
    login_to_start: 'Melden Sie sich an, um Ihre Lieferscheine zu bearbeiten.',
    forgot_password: 'Passwort vergessen?',
    login: 'Anmelden',
    password: 'Passwort',
    register: 'Registrieren',
  },
  order_edit: {
    edit_order: 'Lieferschein Bearbeitung',
    edit_for: 'Bearbeitung von',
    save_to_draft: 'In Entwürfe Speichern',
    print_and_send: 'Drucken und Übermitteln',
  },
  orders_view: {
    orders: 'Lieferscheine',
    your_orders: 'Ihre Lieferscheine',
    search_results: 'Suchergebnisse für ',
    no_search_results: 'Keine Suchergebnisse gefunden.',
    no_orders_yet: 'Sie haben noch keine Lieferscheine erstellt.',
    no_orders_drafts: 'Es gibt keine Lieferscheinentwürfe.',
    no_orders: 'Sie haben noch keine Lieferscheine erstellt.',
    no_pending_orders: 'Sie haben keine ausstehenden Lieferscheine vorhanden.',
    archive_orders_note: 'Die Archivierten Lieferscheine werden für maximal 12 Monate aufbewahrt.',
    no_archived_orders: 'Sie haben keine Archivierte Lieferscheine.',
    delete_order: 'Lieferschein löschen?',
    delete_confirm: 'Sind Sie sicher diesen Lieferschein löschen zu wollen?',
  },
  user_profile: {
    my_account_short: 'Mein Konto',
    my_account_text: 'Mein Konto - Kontoverwaltung',
    sign_out_text: 'Ausloggen',
    change_name_title: 'Namen ändern',
    change_name_label_text: 'Vor-und Nachname',
    change_name_placeholder_text: 'Neuen Namen eingeben',
    change_password_label_text: 'Passwort ändern',
    change_password_placeholder_text: 'Neues Passwort',
    change_password_confirm_placeholder_text: 'Neues Passwort bestätigen',
    error_message_username_text: 'Geben Sie einen Namen ein.',
    error_message_new_username_text: 'Geben Sie einen neuen Namen ein.',
    select_language_label_text: 'Bevorzugte Sprache',
    select_language_placeholder_text: 'Wählen Sie Ihre bevorzugte Sprache',
    language_saved_text: 'Sprache gespeichert',
    option_saved_text: 'Option gespeichert',
    additional_options_text: 'Zusätzliche Optionen',
  },
  check_password: {
    lenght_limit: 'Das Passwort muss mindestens 8 Zeichen lang sein',
    complexity_level:
      'Das Passwort muss mind. 1 Grossbuchstaben, 1 Kleinbuchstaben und 1 Ziffer enthalten',
    password_matches: 'Passwörter stimmen nicht überein',
    password_matches_username: 'Das Passwort darf nicht den Kontonamen enthalten',
  },
};
