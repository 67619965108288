import { FBFirestoreRef } from '@/helpers/firebaseServices';

export const ordersPath = FBFirestoreRef.collection('orders');

export function getLocations() {
  return FBFirestoreRef
    .collection('locations');
}

export function getTooltips() {
  return FBFirestoreRef
    .collection('tooltips');
}

export function getUserDetails(userId) {
  return FBFirestoreRef
    .collection('users')
    .doc(userId);
}

export function getUserOrders(userId) {
  return ordersPath
    .where('createdBy', '==', userId);
}

export function getUserOrder(orderId) {
  return ordersPath
    .doc(orderId);
}
