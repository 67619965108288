/* eslint-disable no-empty-pattern */
import { FBAuth, FBModule } from '@/helpers/firebaseServices';

const defaultData = {
  id: '',
  displayName: '',
  email: '',
};

export default {
  namespaced: true,
  state: {
    initialLoading: true,
    id: defaultData.id,
    displayName: defaultData.displayName,
    email: defaultData.email,
  },
  getters: {
    userName: (state) => state.displayName,
    userEmail: (state) => state.email,
  },
  mutations: {
    RESET_STATE(state) {
      state.initialLoading = true;
      state.id = defaultData.id;
      state.displayName = defaultData.displayName;
      state.email = defaultData.email;
    },
    SET_INITIAL_LOADING(state, initialLoading) {
      state.initialLoading = initialLoading;
    },
    SET_DATA(state, data) {
      // Merge new data
      if (data?.id) state.id = data.id;
      if (data?.displayName) state.displayName = data.displayName;
      if (data?.email) state.email = data.email;
    },
    SET_USER_DISPLAY_NAME(state, displayName) {
      state.displayName = displayName;
    },
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    updateInitialLoading({ commit }, initialLoading) {
      commit('SET_INITIAL_LOADING', initialLoading);
    },
    async updateAuthData({ commit }, authData) {
      commit('SET_DATA', {
        ...authData.uid && { id: authData.uid },
        ...authData.displayName && { displayName: authData.displayName },
        ...authData.email && { email: authData.email },
      });

      return Promise.resolve();
    },
    reauthenticateWithCredential({}, { email, password }) {
      const credential = FBModule.auth.EmailAuthProvider.credential(email, password);

      return FBAuth.currentUser.reauthenticateWithCredential(credential)
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));
    },
    updatePassword({}, password) {
      return FBAuth.currentUser.updatePassword(password)
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));
    },
    updateUserDisplayName({ commit }, displayName) {
      return FBAuth.currentUser.updateProfile({
        displayName,
      })
        .then(() => {
          commit('SET_USER_DISPLAY_NAME', displayName);
          Promise.resolve();
        })
        .catch((error) => console.log(error));
    },
  },
};
