import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/performance';

const FBApp = firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
});

// Auth service
export const FBAuth = FBApp.auth();

// Firestore service
export const FBFirestoreRef = FBApp.firestore();

// Storage service
export const FBStorageRef = firebase.storage().ref();

// Performance Monitoring
if (process.env.VUE_APP_MODE !== 'development' || process.env.NODE_ENV !== 'development') {
  firebase.performance();
}

// Global Firebase module
export const FBModule = firebase;
