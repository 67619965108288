<template>
  <div
    v-if="lazyLoaded"
    v-show="isShowing || (!isShowing && !(backdropAfterLeaveDone && modalAfterLeaveDone))"
    ref="modal"
    role="alertdialog"
    aria-modal="true"
    :aria-labelledby="title"
    :aria-describedby="contentDescription"
    tabindex="-1"
    class="fixed inset-0 h-full w-full flex items-center justify-center z-50 touch-none focus:outline-none"
    :class="{ 'blur-desaturate': isShowing && lazyLoaded }"
    @keydown.tab="onTabPress"
    @keydown.esc="closeOnEscapePress ? hideEvent() : null"
  >
    <!-- Backdrop -->
    <Transition
      :name="backdropTransition"
      appear
      @after-enter="backdropAfterEnterDone = true"
      @after-leave="backdropAfterLeaveDone = true"
    >
      <div
        v-show="isShowing"
        class="absolute inset-0 h-full w-full -z-1 touch-none"
        tabindex="-1"
        @click="closeOnBackdropPress ? hideEvent() : null"
      >
        <slot name="backdrop">
          <div class="h-full w-full bg-secondary opacity-50"></div>
        </slot>
      </div>
    </Transition>
    <!-- Modal surface (Wrapping the content) -->
    <div
      class="max-w-full w-full h-full flex items-end justify-center z-20 touch-none tablet:min-w-400 tablet:w-auto
      tablet:items-center"
      @click="closeOnBackdropPress ? hideEvent() : null"
    >
      <Transition
        :name="modalTransition"
        appear
        @after-enter="modalAfterEnterDone = true"
        @after-leave="modalAfterLeaveDone = true"
      >
        <!-- Modal content -->
        <div
          v-show="isShowing"
          class="relative max-h-full max-w-500 w-full flex flex-col bg-white rounded-lg shadow-24dp overflow-hidden
          tablet:max-h-modal"
          @click.stop
        >
          <header>
            <slot
              v-if="$scopedSlots.header"
              name="header"
              :hideModal="hideEvent"
            />
            <div
              v-else
              class="px-20 py-18"
            >
              <h2 class="text-xl text-center leading-tight">
                {{ title }}
              </h2>
            </div>
          </header>
          <section
            ref="content-main"
            class="relative overflow-auto scrolling-touch"
          >
            <slot :hideModal="hideEvent" />
          </section>
          <footer v-if="$scopedSlots.footer">
            <slot
              name="footer"
              :hideModal="hideEvent"
            />
          </footer>
          <!-- After (overlay, etc..) -->
          <slot
            v-if="$scopedSlots.after"
            name="after"
          />
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import FOCUSABLE from '@/helpers/focusableElements';

/**
 * A modal (Modal dialog) component for isShowing users content which overlays the rest of the application.
 * When opened, it traps the user's focus so that keyboard navigation will remain within the modal until it is closed.
 * It supports being closed by clicking outside the modal content or pressing the ESC key.
 */
export default {
  model: {
    prop: 'isShowing',
    event: 'change',
  },
  props: {
    isShowing: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
      validator: (value) => value,
    },
    contentDescription: {
      type: String,
      required: true,
      validator: (value) => value,
    },
    backdropTransition: {
      type: String,
      default: 'transition-fade-slow',
    },
    modalTransition: {
      type: String,
      default: 'transition-modal',
    },
    // Behaviors
    lazyLoad: {
      type: Boolean,
      default: true,
    },
    resetScroll: {
      type: Boolean,
      default: false,
    },
    reserveScrollBarGap: {
      type: Boolean,
      default: true,
    },
    closeOnBackdropPress: {
      type: Boolean,
      default: true,
    },
    closeOnEscapePress: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    lazyLoaded: false,
    backdropAfterEnterDone: false,
    modalAfterEnterDone: false,
    backdropAfterLeaveDone: true,
    modalAfterLeaveDone: true,
  }),
  mounted() {
    if (!this.lazyLoad) this.lazyLoaded = true;
  },
  watch: {
    isShowing: {
      immediate: true,
      handler(value) {
        if (value) {
          this.lazyLoaded = true;
          // Reset after leave states when isShowing modal
          this.backdropAfterLeaveDone = false;
          this.modalAfterLeaveDone = false;

          this.$nextTick(() => {
            // Focus modal
            this.$refs.modal.focus();

            // Reset scroll of modal content
            if (this.resetScroll && this.$refs['content-main']) {
              this.$refs['content-main'].scrollTo(0, 0);
            }

            // Disable body scroll lock
            if (this.$refs['content-main']) {
              disableBodyScroll(this.$refs['content-main'], {
                reserveScrollBarGap: this.reserveScrollBarGap,
              });
            }

            this.showEvent();
          });
        } else {
          // Enable body scroll lock
          if (!this.$refs['content-main']) return;

          enableBodyScroll(this.$refs['content-main']);
        }
      },
    },
    transitionsAfterEnterDone: {
      handler(value) {
        if (!value) return;

        this.isShowingEvent();
        this.backdropAfterEnterDone = false;
        this.modalAfterEnterDone = false;
      },
    },
    transitionsAfterLeaveDone: {
      handler(value) {
        if (value) return;

        this.hidingEvent();
        this.backdropAfterLeaveDone = true;
        this.modalAfterLeaveDone = true;
      },
    },
  },
  computed: {
    transitionsAfterEnterDone() {
      return this.backdropAfterEnterDone && this.modalAfterEnterDone;
    },
    transitionsAfterLeaveDone() {
      return !(this.backdropAfterLeaveDone && this.modalAfterLeaveDone);
    },
  },
  methods: {
    showEvent() {
      /**
       * Fired when the modal opens.
       * @event show
       * @type { boolean }
       */
      this.$emit('show');
      this.$emit('change', true);
    },
    isShowingEvent() {
      /**
       * Fired when the modal is open and all transitions are done.
       * @event isShowing
       * @type { boolean }
       */
      this.$emit('shown');
    },
    hideEvent() {
      /**
       * Fired when the modal closes.
       * @event hide
       * @type { boolean }
       */
      this.$emit('hide');
      this.$emit('change', false);
    },
    hidingEvent() {
      /**
       * Fired when the modal is open and all transitions are done.
       * @event hiding
       * @type { boolean }
       */
      this.$emit('hidden');
    },
    onTabPress(event) {
      const { modal } = this.$refs;

      if (!modal) return;

      const focusable = Array.from(modal.querySelectorAll(FOCUSABLE));

      if (!focusable.length) {
        event.preventDefault();
        return;
      }
      if (!modal.contains(document.activeElement)) {
        event.preventDefault();
        focusable[0].focus();
      } else {
        const focusedItemIndex = focusable.indexOf(document.activeElement);

        if (event.shiftKey && focusedItemIndex === 0) {
          focusable[focusable.length - 1].focus();
          event.preventDefault();
        }
        if (!event.shiftKey && focusedItemIndex === focusable.length - 1) {
          focusable[0].focus();
          event.preventDefault();
        }
      }
    },
  },
  beforeDestroy() {
    enableBodyScroll(this.$refs['content-main']);
  },
};
</script>

<style>
</style>
