import { defaultLocale } from '@/i18n';

export default [
  {
    path: '/auth-action',
    props: (route) => ({
      mode: route.query.mode,
      actionCode: route.query.oobCode,
      locale: route.query.lang,
    }),
    redirect: (to) => {
      const { hash, params, query } = to;
      return { path: `/${query.lang}/auth-action`, query };
      // if (query.to === 'foo') {
      //   return { path: '/foo', query: null };
      // }
      // if (hash === '#baz') {
      //   return { name: 'baz', hash: '' };
      // }
      // if (params.id) {
      //   return '/with-params/:id';
      // } else {
      //   return '/bar';
      // }
    },
  },
  {
    path: '/:locale/auth-action',
    name: 'AuthAction',
    component: () => import('../views/auth/AuthAction'),
    props: (route) => ({
      mode: route.query.mode,
      actionCode: route.query.oobCode,
      locale: route.query.lang,
    }),
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/:locale/sign-in',
    name: 'SignIn',
    component: () => import('../views/auth/SignIn'),
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/:locale/reset-password',
    name: 'RequestNewPassword',
    component: () => import('../views/auth/RequestNewPassword'),
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/:locale/set-new-password',
    name: 'SetNewPassword',
    component: () => import('../views/auth/SetNewPassword'),
    props: (route) => ({ actionCode: route.query.actionCode }),
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/:locale/app',
    name: 'Orders',
    component: () => import('../views/order/Orders'),
    meta: {
      layout: 'AppLayout',
      authRequired: true,
    },
  },
  {
    path: '/:locale/app/order/add',
    name: 'OrderAdd',
    component: () => import('../views/order/OrderAdd'),
    meta: {
      layout: 'AppLayout',
      authRequired: true,
    },
  },
  {
    path: '/:locale/app/order/edit/:id',
    name: 'OrderEdit',
    component: () => import('../views/order/OrderEdit'),
    props: true,
    meta: {
      layout: 'AppLayout',
      authRequired: true,
    },
  },
  {
    path: '/:locale/app/order/preview/:id',
    name: 'OrderPreview',
    component: () => import('../views/order/OrderPreview'),
    props: true,
    meta: {
      layout: 'AppLayout',
      authRequired: true,
    },
  },
  {
    path: '/:locale/user-profile',
    name: 'UserProfile',
    component: () => import('../views/UserProfile'),
    meta: {
      layout: 'AppLayout',
      authRequired: true,
    },
  },
  {
    //   // TODO: Remove from production
    //   path: '/:locale/experimental',
    //   name: 'Experimental',
    //   component: () => import('../views/experimental/ExperimentalTheme'),
    //   meta: {
    //     layout: 'AppLayout',
    //     authRequired: true,
    //   },
    // }, {
    path: '/:locale/*',
    redirect: { name: 'SignIn' },
  },
  {
    path: '*',
    redirect: { name: 'SignIn', params: { locale: defaultLocale } },
  },
];
