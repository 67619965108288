<template functional>
  <div
    class="flex items-center justify-center rounded-full"
    :class="data.staticClass"
  >
    <slot />
  </div>
</template>


<script>
export default {};
</script>
