<template>
  <div class="min-h-full bg-gray-50 print:bg-white">
    <header class="fixed top-0 h-header w-full flex items-center justify-center transition-shadow duration-100 z-50
      print:hidden" :class="{ 'bg-white shadow': scrollY > 0 }">
      <div class="flex-shrink-0 h-header w-sidebar flex items-center justify-center px-20">
        <img src="/images/galvaswiss-brand-logo.png" alt="GalvaSwiss">
      </div>
      <div class="flex-grow max-w-main h-full flex items-center justify-between">
        <div class="flex-grow flex items-center justify-between mx-20">
          <h1 class="flex-shrink-0 text-lg leading-tight mr-15 laptop:mr-30">
            <PortalTarget name="title" slim>
            </PortalTarget>
          </h1>
          <Transition name="transition-fade-slow">
            <PortalTarget v-if="userAcceptedConditions" name="header" slim />
          </Transition>
        </div>
        <div class="flex-shrink-0 pr-20">
          <UIDropdown trigger-classes="pl-6 pr-2 py-2 focus:outline-none">
            <template #trigger>
              <AvatarChip class="flex-shrink-0 h-50 w-50 font-bold tracking-wide bg-white shadow select-none">
                {{ userInitials }}
              </AvatarChip>
            </template>
            <ul class="min-w-content py-5 bg-white rounded-lg shadow-xl">
              <RouterLink tag="li" :to="{ name: 'UserProfile', params: { locale: $i18n.locale } }" class="leading-tight flex items-center text-secondary py-10 pl-25 pr-30 cursor-pointer transition-colors
                duration-200 ease-in-out-quart hover:bg-gray-100">
                <IconUserCircle class="flex-shrink-0 h-20 w-20 mr-15" />
                {{ $t('navi.account_management') }}
              </RouterLink>
              <li class="leading-tight flex items-center text-secondary py-10 pl-25 pr-30 cursor-pointer transition-colors
                duration-200 ease-in-out-quart hover:text-danger hover:bg-gray-100" @click="signUserOut">
                <IconDoorExit class="flex-shrink-0 h-20 w-20 mr-15" />
                {{ $t('navi.logoff') }}
              </li>
            </ul>
          </UIDropdown>
        </div>
      </div>
    </header>
    <div class="min-w-content flex justify-center pt-header print:pt-0">
      <aside class="flex-shrink-0 w-sidebar print:hidden">
        <NavigationMenu :disabled="!userAcceptedConditions" :show-orders-tooltip-helper="showOrdersTooltipHelper"
          class="fixed top-spacing-header h-full w-sidebar pt-10 z-50"
          :class="{ 'bg-gray-50 border-r border-gray-200': scrollX > 0 }" />
      </aside>
      <main class="flex-grow max-w-main pr-10 pb-20 ml-5 laptop:pr-20 print:px-0">
        <Transition name="transition-fade-fast" mode="out-in">
          <RouterView v-if="userAcceptedConditions" />
        </Transition>
      </main>
    </div>
    <UIModal v-model="showAcceptConditionsModal" :title="$t('order_body.no_treatment')"
      :content-description="$t('order_body.no_treatment')" :close-on-backdrop-press="false"
      :close-on-escape-press="false">
      <template #header>
        <h2 title="AGBs akzeptieren"
          class="font-medium text-xl leading-tight text-center px-8 my-15 line-clamp-2 tablet:px-25 tablet:my-25">
          {{ $t('navi.welcome') }}
        </h2>
      </template>
      <template #default>
        <form class="mb-10 ml-20 max-w-320" novalidate @submit.prevent="setUserNewPassword">
          <label>
            <h2 class="font-bold text-base mb-10">
              <!-- Geben Sie Ihr neues Passwort ein -->
              {{ $t('navi.change_password') }}
            </h2>
            <span class="flex flex-col mb-2 mobile-l:flex-row">
              <input v-model="userPassword" type="password" :placeholder="$t('navi.new_password')"
                class="min-h-35 min-w-0 w-250 border border-gray-300 px-10 bg-white rounded mb-20">
            </span>
          </label>
          <label>
            <!-- <h2
              class="font-bold text-base mb-10"
            >
              Geben Sie Ihr neues Passwort erneut ein
            </h2> -->
            <span class="flex flex-col mb-2">
              <input v-model="repeatUserPassword" type="password" placeholder="Neues Passwort bestätigen"
                class="min-h-35 min-w-0 w-250 border border-gray-300 px-10 bg-white rounded mr-10">
              <SmoothReflow tag="strong"
                :options="{ ...heightReflowOptions, transitionEvent: { propertyName: 'opacity' } }" class="field-error">
                <Transition name="transition-fade-slow">
                  <span v-if="requestErrorMessage">
                    {{ requestErrorMessage }}
                  </span>
                  <!-- <span v-else-if="requestErrorMessage">
                    {{ requestErrorMessage }}
                  </span> -->
                </Transition>
              </SmoothReflow>
            </span>
          </label>
          <button type="submit" :disabled="isPasswordChanged"
            class="button button-primary button-lg button-filled h-35 px-30 mt-10">
            {{ $t('generic_crud_labels.save') }}
          </button>
          <SmoothReflow v-if="isPasswordChanged" tag="strong"
            :options="{ ...heightReflowOptions, transitionEvent: { propertyName: 'opacity' } }"
            class="success-message mt-10 flex">
            <Transition name="transition-fade-slow">
              <span>
                {{ $t('navi.password_changed') }}
              </span>
            </Transition>
          </SmoothReflow>
        </form>
        <div class="flex flex-col items-center px-25 mb-10 tablet:mb-20">
          <div class="relative h-70 w-70 flex items-center justify-center bg-gray-200 rounded-full">
            <IconDeliver class="icon-deliver text-primary h-40" />
          </div>
          <div class="mt-20">
            <div class="flex mb-5">
              <UIInputCheckbox v-model="isAGB" input-classes="checkbox-input secondary" class="mr-5 items-center">
              </UIInputCheckbox>
              <p class="text-center text-xs">
                {{ $t('navi.i_accept') }}
                <a :href="`https://www.galvaswiss.ch/${$i18n.locale}/downloads/agbs.html`" target="_blank"
                  rel="noopener" class="font-semibold underline">{{ $t('navi.terms_and_conditions') }}</a>
                {{ $t('navi.from') }}
              </p>
            </div>
            <div class="flex">
              <UIInputCheckbox v-model="isDatenschutz" input-classes="checkbox-input secondary"
                class="mr-5 items-center">
              </UIInputCheckbox>
              <p class="text-center text-xs">
                {{ $t('navi.i_accept') }}
                <a :href="`https://www.galvaswiss.ch/${$i18n.locale}/datenschutz.html`" target="_blank" rel="noopener"
                  class="font-semibold underline">{{ $t('navi.data_security') }}</a>
                {{ $t('navi.from') }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex items-center justify-between py-20 px-20 mobile-l:pb-15 mobile-l:pt-12
          touch-device:flex-row-reverse">
          <div class="flex -mx-5 touch-device:flex-row-reverse">
            <div class="px-5">
              <button :disabled="!isPasswordChanged || !isAGB || !isDatenschutz"
                class="button button-primary button-lg button-filled" @click="acceptTermsAndConditions">
                {{ $t('navi.register') }}
              </button>
            </div>
            <div class="px-5">
              <button class="button button-secondary button-lg button-text" @click="signUserOut">
                {{ $t('navi.logoff') }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </UIModal>
  </div>
</template>

<script>
import UIInputCheckbox from '@/components/UI/input/UIInputCheckbox';
import { FBAuth } from '@/helpers/firebaseServices';
import { mapGetters } from 'vuex';
import { useWindowScroll } from '@/composables/useWindowScroll';
import SmoothReflow from '@/components/SmoothReflow';
import IconDeliver from '@/components/icon/IconDeliver';
import IconDoorExit from '@/components/icon/IconDoorExit';
import IconUserCircle from '@/components/icon/IconUserCircle';
import UIModal from '@/components/UI/UIModal';
import UIDropdown from '@/components/UI/UIDropdown';
import AvatarChip from '@/components/AvatarChip';
import NavigationMenu from '@/components/NavigationMenu';
import UIModule from '@/store/modules/UI/UI';
import userDetailsModule from '@/store/modules/user/userDetails';
import { getUserDetails } from '@/helpers/firestoreRefs';
import { heightReflowOptions } from '@/helpers/reflowOptions';
import checkPassword from '@/helpers/functions/checkPassword';
import { availableLocales, defaultLocale } from '@/i18n';

const defaultUserInitials = 'GS';

export default {
  components: {
    IconDeliver,
    IconDoorExit,
    IconUserCircle,
    UIModal,
    UIDropdown,
    AvatarChip,
    NavigationMenu,
    SmoothReflow,
    UIInputCheckbox,
  },
  setup() {
    const { x, y } = useWindowScroll();

    return {
      scrollX: x,
      scrollY: y,
    };
  },
  data: () => ({
    heightReflowOptions: Object.freeze(heightReflowOptions),
    unsubscribeUserDetails: null,
    showAcceptConditionsModal: false,
    userPassword: '',
    repeatUserPassword: '',
    isPasswordChanged: false,
    requestErrorMessage: '',
    isAGB: false,
    isDatenschutz: false,
    showTexts: false,
    preferredLocale: defaultLocale,
  }),
  watch: {
    'userDetails.initialLoading': {
      immediate: true,
      handler(value) {
        if (value === false) {
          if (!this.userDetails.acceptedConditions) this.showAcceptConditionsModal = true;
          if (!this.userDetails.preferredDisplayLocale) this.preferredLocale = this.userDetails.preferredDisplayLocale;
        }
      },
    },
  },
  created() {
    if (!this.$store.state?.UI) this.$store.registerModule('UI', UIModule);
    if (!this.$store.state.user?.details) this.$store.registerModule(['user', 'details'], userDetailsModule);


    this.$watch(() => {
      if (this.userInitialLoading) return false;
      return this.userPreferredLocale;
    }, (userPreferredLocale) => {
      if (userPreferredLocale === false) return;

      if (availableLocales.includes(userPreferredLocale) && this.$route.params.locale !== userPreferredLocale) {
        this.$router.replace({
          params: {
            ...this.$route.params,
            locale: userPreferredLocale,
          },
          query: this.$route.query,
        }).then(() => {
          this.$i18n.locale = userPreferredLocale;
          this.showTexts = true;
        }).catch((error) => {
          console.error(error);
        });
      } else {
        this.showTexts = true;
      }
    }, {
      immediate: true,
    });


    this.unsubscribeUserDetails = getUserDetails(this.$store.state.user.auth.id)
      .onSnapshot((snapshot) => {
        this.$store.dispatch('user/details/onDetailsSnapshot', snapshot);
      }, (error) => {
        console.error(error);
      });
  },
  computed: {
    ...mapGetters('user/auth', {
      userEmail: 'userEmail',
    }),
    userDetails() {
      return this.$store.state.user?.details;
    },
    userInitialLoading() {
      return this.$store.state.user?.details.initialLoading;
    },
    userAcceptedConditions() {
      if (!this.userDetails) return false;
      return !this.userDetails.initialLoading && this.userDetails.acceptedConditions;
    },
    userInitials() {
      if (!this.$store.state.user.auth.displayName) return defaultUserInitials;

      const displayNameWords = this.$store.state.user.auth.displayName.split(' ');

      if (!displayNameWords.length) return defaultUserInitials;
      return displayNameWords[0].charAt(0) + displayNameWords[displayNameWords.length - 1].charAt(0);
    },
    userPreferredLocale() {
      if (!this.userDetails) return defaultLocale;
      if (!this.userDetails.preferredDisplayLocale) return defaultLocale;

      return this.userDetails.preferredDisplayLocale;
    },
    showOrdersTooltipHelper() {
      // Using nextTick as the getters are only computed after the loadingStatus has been set already
      this.$nextTick(() => (this.$store.state.user?.orders?.loadingStatus === 'init-loaded'
        || this.$store.state.user?.orders?.loadingStatus === 'snapshot-loaded')
        && !this.$store.getters['user/orders/active']?.length
        && this.userAcceptedConditions
        && this.$route.name !== 'OrderAdd'
        && this.$route.name !== 'OrderEdit');
      return false;
    },
  },
  methods: {
    acceptTermsAndConditions() {
      this.showAcceptConditionsModal = false;

      getUserDetails(this.$store.state.user.auth.id)
        .update({
          acceptedConditions: true,
        })
        .catch((error) => {
          console.error(error);
        });
    },
    signUserOut() {
      // Signing user out, the other user data (user/details, user/orders) are removed from VueX
      FBAuth.signOut()
        .then(() => {
          this.$router.replace({ name: 'SignIn' });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setUserNewPassword() {
      this.requestErrorMessage = '';

      const passwordError = checkPassword(this.userPassword, this.userEmail, this.repeatUserPassword);
      if (passwordError) {
        this.requestErrorMessage = this.$t(`check_password.${passwordError}`);
        setTimeout(() => {
          this.requestErrorMessage = '';
        }, 4000);
      } else if (this.userPassword !== this.repeatUserPassword) {
        this.requestErrorMessage = $t('errors.passwordpasswordMismatch');
        setTimeout(() => {
          this.requestErrorMessage = '';
        }, 4000);
      } else {
        this.$store.dispatch('user/auth/updatePassword', this.repeatUserPassword)
          .then(() => {
            this.isPasswordChanged = true;
          })
          .catch((error) => {
            this.requestErrorMessage = error.message;
          });
      }
    },
  },
  beforeDestroy() {
    if (this.unsubscribeUserDetails !== null) this.unsubscribeUserDetails();

    // Resetting App state
    const resetState = [];

    resetState.push(this.$store.dispatch('user/details/resetState'));
    resetState.push(this.$store.dispatch('user/orders/resetState'));

    if (this.$store.state?.UI?.locations) resetState.push(this.$store.dispatch('UI/locations/resetState'));
    if (this.$store.state?.UI?.tooltips) resetState.push(this.$store.dispatch('UI/tooltips/resetState'));

    Promise.all(resetState)
      .then(() => {
        if (this.$store.state?.UI?.locations) this.$store.unregisterModule(['UI', 'locations']);
        if (this.$store.state?.UI?.tooltips) this.$store.unregisterModule(['UI', 'tooltips']);
        if (this.$store.state?.UI) this.$store.unregisterModule('UI');

        if (this.$store.state.user?.details) this.$store.unregisterModule(['user', 'details']);
        if (this.$store.state.user?.orders) this.$store.unregisterModule(['user', 'orders']);
      });
  },
};
</script>

<style>
.icon-deliver {
  & .secondary {
    @apply fill-secondary-dark;
  }
}

.field-error {
  @apply font-semibold text-sm text-danger leading-tight mt-4;
}

.success-message {
  @apply font-semibold text-sm text-success leading-tight mt-4;
}
</style>
