<template>
  <div class="min-h-full w-full flex flex-col items-center justify-center p-20">
    <img
      src="/images/galvaswiss-brand-logo.png"
      alt="GalvaSwiss"
      class="h-40 mb-60 mx-auto mobile-l:h-45"
    >
    <SmoothReflow
      tag="div"
      :options="{
        ...heightReflowOptions,
        hideOverflow: false,
        transitionEvent: { selector: '.router-view', propertyName: 'opacity' }
      }"
    >
      <Transition
        name="transition-fade-medium"
        mode="out-in"
      >
        <RouterView v-if="showRouterView" class="router-view" />
      </Transition>
    </SmoothReflow>
  </div>
</template>


<script>
import SmoothReflow from '@/components/SmoothReflow';
import { heightReflowOptions } from '@/helpers/reflowOptions';


export default {
  components: {
    SmoothReflow,
  },
  data: () => ({
    showRouterView: false,
    heightReflowOptions: Object.freeze(heightReflowOptions),
  }),
  watch: {
    '$store.state.user.auth.initialLoading': {
      immediate: true,
      handler(value) {
        if (value) return;
        if (this.$store.state.user.auth.id) {
          this.$router.replace({
            name: 'Orders',
            params: {
              locale: this.$i18n.locale,
            },
          });
        } else {
          this.showRouterView = true;
        }
      },
    },
  },
};
</script>
