<script>
export default {
  functional: true,
  props: {
    layouts: {
      type: Object,
    },
    current: {
      type: String,
    },
  },
  render(h, { props, children }) {
    const { current, layouts } = props;

    return h(layouts[current], { props }, children);
  },
};
</script>
