<template>
  <div id="app" class="h-full">
    <template v-if="!isIE">
      <RouterLanguage v-if="routerIsReady">
        <RouterLayout :layouts="$options.LAYOUTS" :current="$route.meta.layout" />
      </RouterLanguage>
    </template>
    <div v-else class="h-full flex flex-col items-center justify-center">
      <h1>Your browser is out-of-date!</h1>
      <p>Update your browser to use the GalvaSwiss App and the newest features.</p>
      <a href="http://outdatedbrowser.com/" class="text-white px-40 py-15 mt-20 bg-primary rounded-lg">Update my
        browser</a>
    </div>
  </div>
</template>


<script>
import RouterLanguage from '@/components/router/RouterLanguage';
import RouterLayout from '@/components/router/RouterLayout';
import DefaultLayout from '@/layouts/DefaultLayout';
import AuthLayout from '@/layouts/AuthLayout';
import AppLayout from '@/layouts/AppLayout';


const LAYOUTS = { // TODO: Check if lazy-load is possible
  DefaultLayout,
  AuthLayout,
  AppLayout,
};

export default {
  components: {
    RouterLanguage,
    RouterLayout,
  },
  data: () => ({
    routerIsReady: false,
    isIE: false,
  }),
  created() {
    // IE detection
    if (navigator.appName === 'Microsoft Internet Explorer'
      || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
      this.isIE = true;
    }

    this.$router.onReady(() => {
      this.routerIsReady = true;
    }, (error) => {
      console.error(error);
    });
  },
  LAYOUTS, // Adding layouts constant as $options
};
</script>


<style>
.bg-primary {
  /* For IE support only */
  background-color: hsl(354, 100%, 44%);
}
</style>
