import { FBAuth } from '@/helpers/firebaseServices';
import store from '@/store';


export default {
  install: () => {
    // Subscribe to user Auth change (login/logout)
    FBAuth.onAuthStateChanged((user) => {
      // User is not signed-in
      if (!user) {
        store.dispatch('user/auth/resetState');
        if (store.state.user.auth.initialLoading) store.dispatch('user/auth/updateInitialLoading', false);
        return;
      }

      // User is signed in
      store.dispatch('user/auth/updateAuthData', user).then(() => {
        if (store.state.user.auth.initialLoading) store.dispatch('user/auth/updateInitialLoading', false);
      });
    });
  },
};
