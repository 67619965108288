<template>
  <nav class="laptop:pr-15">
    <UITooltip :visible="showOrdersTooltipHelper" to="button-create" theme="galvaswiss" animation="shift-away" arrow
      arrow-type="round" distance="15" placement="right" trigger="manual" class="hidden">
      <h2 class="text-lg text-left mb-7">{{ $t('navi.create_order') }}</h2>
      {{ $t('navi.create_note') }}
    </UITooltip>
    <ul class="flex flex-col transition-opacity duration-200 ease-in-out-quart"
      :class="{ 'opacity-25 pointer-events-none': disabled }">
      <li v-for="(navigationItem) in navigationItems" :key="`navigation-item-${navigationItem.label.text}`"
        tabindex="-1" :class="navigationItem.classes">
        <RouterLink :to="navigationItem.router.to"
          :name="navigationItem.router.attrs ? navigationItem.router.attrs.name : ''" class="flex items-center"
          :class="navigationItem.router.classes">
          <component :is="navigationItem.icon.component" class="icon w-35 mr-10" :class="navigationItem.icon.classes" />
          <span :class="navigationItem.label.classes">
            {{ navigationItem.label.text }}
          </span>
        </RouterLink>
      </li>
    </ul>
    <div class="self-center mt-10 absolute bottom-110" :class="{ 'opacity-25 pointer-events-none': disabled }">
      <a href="https://get.anydesk.com/Tse1BHs1/Galvaswiss_AG_Support.exe"
        class="flex items-center button-primary button-lg button-filled inline-flex items-center ml-20 bottom-110 w-158">
        <IconSupport class="h-24 print:hidden" />
        <span class="ml-14">
          {{ $t('navi.support') }}
        </span>
      </a>
    </div>
  </nav>
</template>


<script>
import IconAdd from '@/components/icon/IconAdd';
import IconSupport from '@/components/icon/IconSupport';
import IconDeliver from '@/components/icon/IconDeliver';
import IconEdit from '@/components/icon/IconEdit';
import IconUserCircle from '@/components/icon/IconUserCircle';


export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    showOrdersTooltipHelper: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IconAdd,
    IconSupport,
    IconDeliver,
    IconEdit,
    IconUserCircle,
  },
  data: () => ({
    savingOrder: false,
  }),
  computed: {
    navigationItems() {
      return [{
        router: {
          to: {
            name: 'Orders', params: { locale: this.$i18n.locale },
          },
          classes: 'menu-item',
        },
        label: {
          text: this.$t('orders_view.orders'),
          classes: 'label text-sm',
        },
        icon: {
          component: IconDeliver,
          classes: 'h-24',
        },
        isVisible: true,
      }, {
        classes: this.$route.name === 'Orders' ? 'self-center mt-10' : '',
        router: {
          to: {
            name: 'OrderAdd', params: { locale: this.$i18n.locale },
          },
          classes: this.$route.name === 'Orders'
            ? 'button button-primary button-lg button-filled inline-flex items-center' : 'menu-item',
          attrs: {
            name: 'button-create',
          },
        },
        icon: {
          component: IconAdd,
          classes: this.$route.name === 'Orders' ? 'h-30 w-30 text-white -ml-10' : 'h-35',
        },
        label: {
          text: this.$t('navi.create'),
          classes: this.$route.name === 'Orders' ? '' : 'label text-sm',
        },
        isVisible: ['Orders', 'OrderAdd'].includes(this.$route.name),
      }, {
        router: {
          to: {
            name: 'OrderEdit', params: { locale: this.$i18n.locale, id: this.$route.params.id },
          },
          classes: 'menu-item',
        },
        label: {
          text: this.$t('generic_crud_labels.edit'),
          classes: 'label text-sm',
        },
        icon: {
          component: IconEdit,
          classes: 'h-22',
        },
        isVisible: this.$route.name === 'OrderEdit' && this.$route.params.id,
      }, {
        router: {
          to: {
            name: 'UserProfile', params: { locale: this.$i18n.locale },
          },
          classes: 'menu-item',
        },
        label: {
          text: this.$t('user_profile.my_account_short'),
          classes: 'label text-sm',
        },
        icon: {
          component: IconUserCircle,
          classes: 'icon-user-circle h-22',
        },
        isVisible: this.$route.name === 'UserProfile',
      }].filter((navigationItem) => navigationItem.isVisible);
    },
  },
};
</script>


<style scoped>
.menu-item {
  @apply h-45 text-lg text-gray-700 tracking-wide truncate pl-10 rounded-full select-none cursor-pointer;

  &:focus {
    @apply outline-none;
  }

  &:focus,
  &:hover {
    @apply bg-gray-200;
  }

  &.router-link-exact-active {
    @apply font-bold text-gray-900;

    & .label {
      @apply text-primary;
    }

    & .icon {
      & .primary {
        @apply fill-primary;
      }
    }
  }

  & .icon {
    & .primary {
      @apply fill-gray-400;
    }

    & .secondary {
      @apply fill-secondary;
    }
  }

  & .icon-user-circle {
    & .secondary {
      @apply fill-transparent;
    }
  }
}

@screen laptop {
  .menu-item {
    @apply pl-20;
  }
}
</style>
