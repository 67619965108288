export default function (password, email, repeatPassword) {
  if (password.length < 8) {
    // return 'Das Passwort muss mindestens 8 Zeichen lang sein';
    return 'lenght_limit';
  }
  if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(password)) {
    // return 'Das Passwort muss mind. 1 Grossbuchstaben, 1 Kleinbuchstaben und 1 Ziffer enthalten';
    return 'complexity_level';
  }
  if (password !== repeatPassword) {
    // return 'Passwörter stimmen nicht überein';
    return 'password_matches';
  }
  if (password.toUpperCase().includes(email.split('@')[0].toUpperCase())) {
    // return 'Das Passwort darf nicht den Kontonamen enthalten';
    return 'password_matches_username';
  }
  return '';
}
