<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :class="['icon-edit', data.class, data.staticClass]"
  >
    <!-- eslint-disable -->
    <path
      d="M4 14a1 1 0 0 1 .3-.7l11-11a1 1 0 0 1 1.4 0l3 3a1 1 0 0 1 0 1.4l-11 11a1 1 0 0 1-.7.3H5a1 1 0 0 1-1-1v-3z"
      class="primary fill-current"
    />
    <!-- eslint-enable -->
    <rect
      width="20"
      height="2"
      x="2"
      y="20"
      rx="1"
      class="secondary fill-current"
    />
  </svg>
</template>


<script>
export default {};
</script>
