<template>
  <label
    tabindex="0"
    @keydown.space.stop.prevent="onChange"
  >
    <span
      class="flex items-center justify-center"
      :class="inputWrapperClasses"
    >
      <input
        :disabled="isDisabled"
        :value="val"
        :checked="isChecked"
        type="checkbox"
        tabindex="-1"
        class="checkbox-input cursor-pointer focus:outline-none"
        :class="inputClasses"
        @change="onChange"
      >
    </span>
    <slot />
  </label>
</template>


<script>
export default {
  props: {
    inputWrapperClasses: {
      type: [Array, String],
      default: '',
    },
    inputClasses: {
      type: [Array, String],
      default: '',
    },
    value: {
      type: [Boolean, Array, String],
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    val: {
      type: [String, Array],
      default: '',
    },
  },
  computed: {
    isChecked() {
      if (Array.isArray(this.value)) return this.value.includes(this.val);

      return this.value;
    },
  },
  methods: {
    onChange() {
      let value = (typeof this.value === 'object') ? this.value.slice() : this.value;

      if (Array.isArray(this.value)) {
        if (value.includes(this.val)) value.splice(value.indexOf(this.val), 1);
        else value.push(this.val);
      } else {
        value = !this.value;
      }

      this.$emit('input', value);
      this.$emit('change', value);
    },
  },
};
</script>
