import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
import VueTheMask from 'vue-the-mask';
import PortalVue from 'portal-vue';
import VueTippy, { TippyComponent } from 'vue-tippy';
import App from './App';
import router from './router';
import store from './store';
import { i18n } from './i18n';
import FirebaseAuth from './plugins/firebaseAuth';
import './assets/styles/tailwind.pcss';
import { setupCalendar } from 'v-calendar';
import './registerServiceWorker';


// Vue Plugins
Vue.use(FirebaseAuth);
Vue.use(VueCompositionApi);
Vue.use(VueTippy, {
  directive: 'tooltip',
  flipDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false,
      },
      hide: {
        enabled: false,
      },
    },
  },
});
Vue.use(VueTheMask);
Vue.use(PortalVue, {
  portalName: 'Portal',
  portalTargetName: 'PortalTarget',
});

// Vue global components
Vue.component('UITooltip', TippyComponent);
setupCalendar({
  fr: {
    firstDayOfWeek: 1,
    masks: {
      L: 'DD/MM/YYYY',
    },
  },
  de: {
    firstDayOfWeek: 1,
    masks: {
      L: 'DD.MM.YYYY',
    },
  },
  en: {
    firstDayOfWeek: 1,
    masks: {
      L: 'DD/MM/YYYY',
    },
  },
});

// Vue config
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
