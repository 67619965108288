<template functional>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="100%" height="100%" viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#ffff" stroke="none">
      <path d="M3694 5109 c-34 -10 -247 -187 -258 -214 -33 -88 41 -177 129 -154
        34 9 248 187 259 214 11 31 6 90 -10 114 -13 19 -74 54 -86 50 -2 0 -17 -5
        -34 -10z"/>
      <path d="M2770 4947 c-358 -129 -459 -185 -625 -352 -279 -279 -430 -657 -466
        -1169 -11 -161 2 -485 24 -573 28 -110 165 -125 207 -23 9 23 10 56 1 143 -6
        62 -11 196 -11 298 0 266 27 443 100 660 72 218 163 366 314 514 128 126 209
        174 430 254 138 51 171 60 181 49 7 -7 94 -236 193 -508 99 -272 184 -503 188
        -513 5 -14 -12 -36 -76 -95 -236 -219 -386 -436 -431 -622 -33 -139 -2 -345
        95 -627 112 -324 230 -531 360 -630 161 -122 347 -175 706 -203 73 -5 138 -14
        143 -19 13 -13 377 -1009 377 -1031 0 -13 -16 -25 -57 -40 -289 -109 -374
        -130 -534 -130 -236 0 -485 82 -708 234 -136 93 -149 97 -208 69 -68 -32 -82
        -123 -25 -175 45 -42 189 -134 291 -187 98 -50 225 -99 329 -125 162 -42 410
        -50 558 -17 91 19 379 118 444 151 48 25 104 88 121 136 30 90 24 114 -180
        668 -105 285 -201 537 -215 560 -55 95 -101 112 -348 132 -288 23 -460 72
        -571 164 -107 89 -251 401 -333 719 -78 302 -29 434 282 751 89 91 170 181
        180 200 13 24 18 57 18 105 0 66 -10 97 -186 575 -222 601 -210 571 -251 613
        -83 82 -174 95 -317 44z"/>
      <path d="M4090 4781 c-235 -78 -397 -143 -422 -171 -64 -70 -16 -180 79 -180
        26 0 377 122 451 157 84 40 81 163 -5 199 -39 17 -35 17 -103 -5z"/>
      <path d="M3736 4265 c-55 -29 -75 -83 -52 -140 25 -58 44 -64 217 -67 175 -3
       197 3 224 59 31 65 -7 143 -76 157 -18 3 -88 6 -158 6 -93 0 -134 -4 -155 -15z"/>
      <path d="M1530 2570 c-93 -9 -173 -37 -219 -76 -44 -38 -57 -70 -66 -159 -14
        -140 -11 -133 -78 -167 -34 -17 -81 -44 -105 -60 -48 -33 -41 -35 -177 26 -95
        42 -114 43 -181 12 -136 -63 -285 -309 -287 -472 -1 -83 20 -115 126 -194 l87
        -65 0 -125 0 -126 -87 -64 c-106 -79 -127 -111 -126 -194 0 -114 85 -295 189
        -401 46 -48 132 -95 173 -95 13 0 72 21 132 46 l109 47 53 -36 c30 -19 76 -47
        104 -61 56 -28 54 -24 68 -161 9 -90 22 -121 67 -160 50 -43 112 -64 220 -75
        148 -15 304 13 376 67 48 36 62 74 76 194 7 60 15 111 17 113 2 2 33 18 68 36
        36 18 82 44 103 57 l38 26 98 -44 c117 -53 166 -56 234 -16 171 100 315 419
        251 554 -11 23 -47 58 -107 102 l-91 67 1 125 1 124 90 66 c59 44 95 79 106
        102 64 134 -81 455 -251 555 -68 39 -117 35 -234 -17 l-98 -44 -62 40 c-35 22
        -83 51 -108 64 l-44 24 -12 106 c-16 142 -33 174 -119 217 -72 36 -218 54
        -335 42z m195 -229 l30 -6 15 -125 c9 -69 22 -135 29 -148 7 -14 51 -41 114
        -71 56 -27 133 -71 171 -100 88 -65 108 -66 250 -4 l109 48 38 -44 c36 -40 90
        -139 103 -186 4 -16 -12 -32 -84 -84 -137 -98 -136 -96 -131 -204 6 -138 6
        -155 -3 -248 l-8 -86 30 -34 c17 -19 69 -61 116 -94 69 -49 85 -64 80 -80 -13
        -48 -67 -146 -103 -186 l-38 -44 -109 48 c-144 63 -168 62 -254 -4 -61 -47
        -137 -89 -222 -123 -56 -22 -70 -53 -88 -195 l-15 -125 -35 -9 c-46 -12 -227
        -4 -241 10 -6 6 -17 65 -24 131 -7 66 -19 130 -25 142 -6 12 -54 41 -113 69
        -56 27 -133 71 -171 100 -89 66 -113 67 -251 5 -57 -26 -110 -44 -119 -41 -21
        8 -89 113 -117 180 -21 53 -22 60 -7 70 220 159 225 165 208 270 -12 78 -13
        165 0 241 14 88 1 110 -119 197 -56 40 -101 77 -101 82 0 38 107 223 136 234
        8 3 61 -15 118 -41 137 -61 165 -61 242 0 33 25 109 70 169 100 61 29 116 63
        123 75 8 12 20 75 27 141 7 66 17 124 22 128 4 4 17 10 28 13 29 7 185 6 220
        -2z"/>
      <path d="M1555 1719 c-105 -15 -175 -50 -248 -124 -231 -231 -129 -626 186
        -720 63 -19 177 -19 238 -1 213 65 343 267 309 483 -36 233 -254 395 -485 362z
        m177 -254 c63 -45 90 -104 86 -186 -3 -53 -9 -75 -32 -108 -64 -92 -198 -118
        -289 -55 -47 33 -97 119 -97 167 0 80 53 165 124 199 62 29 153 21 208 -17z"/>
    </g>
  </svg>
</template>


<script>
export default {};
</script>
