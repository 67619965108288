<template>
  <div class="relative inline-block">
    <button
      :disabled="isDisabled"
      type="button"
      :aria-expanded="isOpen"
      aria-haspopup="true"
      :class="triggerClasses"
      @click="isOpen = !isOpen"
      @keydown.esc="isOpen = false"
    >
      <slot
        name="trigger"
        :is-open="isOpen"
      />
    </button>

    <transition
      :name="dropdownTransition"
      appear
    >
      <div
        v-show="isOpen"
        class="mt-2 absolute right-0 origin-top-right text-left z-10"
        :class="dropdownClasses"
        @focusout="onFocusOut"
        @click="onDropdownPress"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>


<script>
export default {
  props: {
    dropdownTransition: {
      type: String,
      default: 'transition-dropdown',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    dropdownClasses: {
      type: [Array, Object, String],
      default: '',
    },
    triggerClasses: {
      type: [Array, Object, String],
      default: '',
    },
  },
  mounted() {
    document.addEventListener('click', this.onClickOut);
  },
  data: () => ({
    isOpen: false,
  }),
  methods: {
    onClickOut(event) {
      if (this.isOpen && !this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    onFocusOut(event) {
      if (this.isOpen && !this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    onDropdownPress() {
      this.isOpen = false;
      this.$emit('dropdown-press');
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClickOut);
  },
};
</script>


<style>
</style>
