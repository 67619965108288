<template>
  <div>
    <slot />
  </div>
</template>


<script>
export default {};
</script>
