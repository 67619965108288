import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";
import { FBAuth } from "@/helpers/firebaseServices";
import { loadLocaleAsync } from "@/i18n";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
});

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = FBAuth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    // Checking if this route requires auth
    // if not, redirect to sign-in view.

    // On first sign-in the store is not set yet, verify directly
    if (!store.state?.user?.auth || store.state?.user?.auth?.initialLoading === true) {
      const user = await getCurrentUser();

      if (!user) {
        next({
          name: "SignIn",
          params: { ...to.params },
        });
      } else next();
    }
    if (!store.state.user.auth.id) {
      next({
        name: "SignIn",
        params: { ...to.params },
      });
    } else next();
  }
  next();
});

// Handling i18n
router.beforeEach((to, from, next) => {
  // Load new language when the language changes
  loadLocaleAsync(to.params.locale).then(() => next());
});

export default router;
