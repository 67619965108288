/* eslint-disable no-empty-pattern */
import { FBAuth } from '@/helpers/firebaseServices';
import { defaultLocale } from '@/i18n';

export default {
  namespaced: true,
  state: {
    initialLoading: true,
    acceptedConditions: false,
    preferredDisplayLocale: defaultLocale,
    humanReadableIdKey: 'GAS',
    locations: [],
  },
  getters: {},
  mutations: {
    RESET_STATE(state) {
      state.initialLoading = true;
      state.acceptedConditions = false;
      state.humanReadableIdKey = 'GAS';
      state.locations = [];
      state.preferredDisplayLocale = defaultLocale;
    },
    SET_INITIAL_LOADING(state, initialLoading) {
      state.initialLoading = initialLoading;
    },
    SET_DATA(state, data) {
      // Merge new data
      if (data.acceptedConditions) state.acceptedConditions = data.acceptedConditions;
      if (data.humanReadableIdKey) state.humanReadableIdKey = data.humanReadableIdKey;
      if (data.locations) state.locations = data.locations;
      if (data.preferredDisplayLocale) state.preferredDisplayLocale = data.preferredDisplayLocale;
    },
  },
  actions: {
    async resetState({ commit }) {
      commit('RESET_STATE');

      return Promise.resolve();
    },
    onDetailsSnapshot({ state, commit }, snapshot) {
      commit('SET_DATA', {
        ...snapshot.data(),
      });

      if (state.initialLoading) commit('SET_INITIAL_LOADING', false);
    },
  },
};
