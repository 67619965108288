<template>
  <div class="h-full">
    <slot>
      <RouterView />
    </slot>
  </div>
</template>


<script>
export default {};
</script>
