import Vue from 'vue';
import Vuex from 'vuex';
import userModule from './modules/user/user';


Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.VUE_APP_MODE === 'development' || process.env.NODE_ENV === 'development',
  modules: {
    user: userModule,
  },
});
